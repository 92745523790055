import * as React from 'react'
import * as css from './AllArticleList.module.css'
import { GlobalContext } from 'components/Context'
import PostPreview from 'components/common/PostPreview'
import NoPosts from 'components/common/NoPosts'

const AllArticleList = () => {
  const {
    allPosts,
    $selectedCategory,
    $limitArticles, set$limitArticles,
  } = React.useContext(GlobalContext)

  const posts = !!$selectedCategory
    ? allPosts
      .filter(post => post.category.map(({ seo }) => seo.slug.current).includes($selectedCategory))
    : allPosts
      .filter(post => !post.hideFromHomepage)

  const [firstPost, ...theRest] = posts

  return (
    <div>
      {firstPost && <PostPreview post={firstPost} large key={firstPost.seo.slug.current} />}

      {!!theRest.length && <>
        <div className={css.list}>
          {theRest.map((post, key) => (key < $limitArticles) && (
            <PostPreview post={post} key={post.seo.slug.current} />
          ))}
        </div>

        {theRest.length > $limitArticles && (
          <div className={css.more}>
            <button className={`${css.btn} button`} onClick={() => set$limitArticles($limitArticles + 6)}>
              View more articles +
            </button>
          </div>
        )}
      </>}

      {posts.length === 0 && <NoPosts/>}
    </div>
  )
}

export default AllArticleList
