import * as React from 'react'
import * as css from './CategoryList.module.css'
import { GlobalContext } from 'components/Context'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const CategoryItem = ({ id, children }) => {
  const { $selectedCategory, set$selectedCategory, set$limitArticles } = React.useContext(GlobalContext)

  function handleClick() {
    set$selectedCategory(id)
    set$limitArticles(6)
  }

  return (
    <li className={clsx(css.item, $selectedCategory === id && 'is-selected')} onClick={handleClick}>
      {children}
    </li>
  )
}

const CategoryList = ({ categories }) => (
  <ul>
    <CategoryItem id={false}>
      All Topics
    </CategoryItem>

    {categories.map((category, key) => (
      <CategoryItem id={category.seo.slug.current} key={key}>
        {category.title}
      </CategoryItem>
    ))}
  </ul>
)

export default CategoryList
