import * as React from 'react'
import * as css from './Subscribe.module.css'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const Subscribe = () => (
  <div className={css.root}>
    <p>Subscribe for insider news & expert tips on solar</p>

    <MailchimpSubscribe
      url="https://gogreensolar.us1.list-manage.com/subscribe/post?u=97167536ead4e9d437c386d38&id=4b8aae1bcb"
      render={({ subscribe, status, message }) => (
        <SubscribeForm
          status={status}
          message={message}
          onValidated={subscribe}
        />
      )}
    />
  </div>
)

const SubscribeForm = ({ status, message, onValidated }) => {
  let email

  const submit = () => email && email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value
    })

  return <>
    <div className={css.wrapper}>
      <label>
        <input
          ref={node => (email = node)}
          className={`${css.input} input`}
          type="email"
          placeholder="Email"
        />
      </label>

      <button className={`${css.btn} input-button`} onClick={submit}>
        Subscribe
      </button>
    </div>

    {status === 'sending' && <div className={css.msg}>Sending...</div>}

    {status === 'error' && <div className={css.msg} dangerouslySetInnerHTML={{ __html: message }} />}

    {status === 'success' && <div className={css.msg} dangerouslySetInnerHTML={{ __html: message }} />}
  </>
}

export default Subscribe
