import * as React from 'react'
import * as css from './NoPosts.module.css'

const NoPosts = () => (
  <div className={css.root}>
    No posts found.
  </div>
)

export default NoPosts
