import * as React from 'react'
import * as css from './home.module.css'
import DefaultLayout from 'layouts/Default'
import MainSidebar from 'components/sidebar/MainSidebar'
import AllArticleList from 'components/common/AllArticleList'
import { graphql, useStaticQuery } from 'gatsby'

const HomePg = () => {
  const { sanitySiteInfo } = useStaticQuery(query)

  return (
    <DefaultLayout
      {...sanitySiteInfo.seo}
      className={`${css.root} container side-padding section-padding`}
    >
      <aside>
        <MainSidebar/>
      </aside>

      <AllArticleList/>
    </DefaultLayout>
  )
}

export default HomePg

const query = graphql`query HomePg {
  sanitySiteInfo {
    seo {
      description
    }
  }
}`
