import * as React from 'react'
import * as css from './MainSidebar.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { GlobalContext } from '../Context'
import { MdArrowDropDown as Down, MdArrowDropUp as Up } from 'react-icons/md'
import CategoryList from './CategoryList'
import Search from './Search'
import Share from './Share'
import Subscribe from './Subscribe'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const MainSidebar = () => {
  const { sanitySiteInfo, allSanityCategory } = useStaticQuery(query)

  const {
    $sidebarOpened, set$sidebarOpened,
    $selectedCategory,
  } = React.useContext(GlobalContext)

  const category = allSanityCategory.nodes
    .find(({ seo }) => seo.slug.current === $selectedCategory)

  return (
    <div className="sticky">
      <h1 className={`${css.title} h2`} onClick={() => set$sidebarOpened(!$sidebarOpened)}>
        {sanitySiteInfo.title}
        {$sidebarOpened ? <Up className="tablet"/> : <Down className="tablet"/>}
      </h1>

      <div className={`${css.category} tablet`} key={category?.title || null}>
        {category?.title || 'All Topics'}
      </div>

      <div className={clsx(css.inner, !$sidebarOpened && 'desktop-and-up')}>
        <CategoryList categories={allSanityCategory.nodes} />
        <Search/>
        <Share/>
        <Subscribe/>
      </div>
    </div>
  )
}

export default MainSidebar

const query = graphql`query CategoryList {
  sanitySiteInfo {
    title
  }
  allSanityCategory(sort: {fields: order}) {
    nodes {
      title
      seo { slug { current } }
    }
  }
}`
