import * as React from 'react'
import * as css from './Search.module.css'
import { IoIosSearch } from 'react-icons/io'
import { navigate } from 'gatsby-link'

const Search = () => {
  const [$query, set$query] = React.useState(null)

  function handleChange({ target }) {
    set$query(target.value)
  }

  function handleKeyUp({ key }) {
    if (key !== 'Enter') return
    search()
  }

  function search() {
    !!$query && navigate(`/search?query=${ $query }`)
  }

  return (
    <div className={css.root}>
      <label>
        <input
          className={`${css.input} input`}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          type="search"
          placeholder="Search"
        />
      </label>

      <button className={`${css.btn} input-button`} onClick={() => search()}>
        <IoIosSearch/>
      </button>
    </div>
  )
}

export default Search
